import React from 'react';

const NotFoundPage = () => (
  <div className="row">
    <div className="col s8 offset-s2">
      <div className="card">
        <div className="card-content">
          <span className="card-title">NOT FOUND</span>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
